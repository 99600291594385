import React, { ChangeEvent, FormEvent, useState } from 'react';
import {
  makeStyles,
  TextField,
  Grid,
  Button,
  Typography,
  Checkbox,
  Theme,
  Card,
  Link,
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  DialogActions,
} from '@material-ui/core';
import { useAppState } from '../../../state';

import { useAppSelector } from '../../../redux/hooks';
import FirebaseApp from '../../../state/useFirebaseAuth/FirebaseApp';

const useStyles = makeStyles((theme: Theme) => ({
  gutterBottom: {
    marginBottom: '1em',
  },
  media: {
    maxWidth: 300,
    maxHeight: 200,
    display: 'block',
    margin: 'auto',
  },
  CheckboxContainer: {
    display: 'flex',
  },
  checklabel: {
    display: 'flex',
  },
  inputContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '2.5em 0 1.5em',
    '& div:not(:last-child)': {
      marginRight: '1em',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '1.5em 0 2em',
    },
  },
  textFieldContainer: {
    width: '100%',
  },
  continueButton: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

interface RoomNameScreenProps {
  name: string;
  roomName: string;
  decoded: any;
  setName: (name: string) => void;
  setRoomName: (roomName: string) => void;
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
}

export default function RoomNameScreen({
  name,
  roomName,
  decoded,
  setName,
  setRoomName,
  handleSubmit,
}: RoomNameScreenProps) {
  const [open, setOpen] = useState(false);
  const urlParams = useAppSelector<any>(state => state.collection.params);
  const data = useAppSelector<any>(state => state.collection.list);
  const [disclosures, setDisclosures] = useState(true);
  const [recordings, setRecordings] = useState(true);
  const classes = useStyles();
  const { user } = useAppState();

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleRoomNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setRoomName(event.target.value);
  };

  const handleCheckDisclosures = (event: any) => {
    setDisclosures(event.target.checked);
  };

  const handleCheckRecordings = (event: any) => {
    setRecordings(event.target.checked);
  };

  const handleOnSubmit = async (event: FormEvent<HTMLFormElement>) => {
    if (urlParams.data.Crm === '1') {
      event.preventDefault();
      const firebase = await FirebaseApp().tokenDataCrm(roomName);
      if (firebase) {
        if (firebase.data?.rm) {
          handleSubmit(event);
        } else {
          setOpen(true);
        }
      }
    } else {
      handleSubmit(event);
    }
  };

  const handleDisclosure = (event: any) => {
    event.preventDefault();
    let url = `https://${data.tokenData.data.host}/#/virtual-disclosure/${decoded.company_id}`
    if (urlParams.data.Crm === '1') {
      url = `${url}?id=${urlParams.data.URLRoomName}`
      window.location.assign(url)
    }else{
      window.open(url);
    }
  };

  const hasUsername = !window.location.search.includes('customIdentity=true') && user?.displayName;

  return (
    <>
      <Card>
        {decoded?.urlLogo && 
          <img 
            className={classes.media}
            src={'https://storage.servicehubcrm.net/dev/user_profile/' + decoded.urlLogo}
            alt="imagen crm company"
          />
        }
        <Typography gutterBottom variant="subtitle2" component="div" align="center">
          Order {decoded.orderNo}
        </Typography>
      </Card>
      <form onSubmit={handleOnSubmit}>
        <div className={classes.inputContainer}>
          {!hasUsername && (
            <div className={classes.textFieldContainer}>
              <TextField
                id="input-user-name"
                variant="standard"
                fullWidth
                label="Your Name"
                helperText="We recommend this name, you can change it freely"
                size="small"
                value={name}
                onChange={handleNameChange}
              />
            </div>
          )}
          <div className={classes.textFieldContainer}>
            <TextField
              disabled
              autoCapitalize="false"
              id="input-room-name"
              variant="standard"
              label="Room Name"
              fullWidth
              size="small"
              value={roomName}
              onChange={handleRoomNameChange}
            />
          </div>
        </div>

        <Grid container>
          <Grid item={true} xs={8}>
            <Grid item={true} xs={12} className={classes.CheckboxContainer}>
              <Checkbox
                checked={disclosures}
                size="small"
                color="secondary"
                onChange={handleCheckDisclosures}
                inputProps={{ 'aria-label': 'controlled' }}
              />
              <Typography variant="caption" className={classes.checklabel}>
                <Link component="button" disabled={!decoded.company_id} onClick={handleDisclosure}>
                  I agree to disclosures
                </Link>
              </Typography>  
            </Grid>
            <Grid item={true} xs={12} className={classes.CheckboxContainer}>
              <Checkbox
                checked={recordings}
                size="small"
                color="secondary"
                onChange={handleCheckRecordings}
                inputProps={{ 'aria-label': 'controlled' }}
              />
              <Typography variant="caption" className={classes.checklabel}>
                <Link component="button" disabled={!decoded.company_id} onClick={handleDisclosure}>
                  I agree to allow recordings
                </Link>
              </Typography>  
            </Grid>
          </Grid>
          <Grid item={true} xs={4}>
            <Button
              className={classes.continueButton}
              variant="contained"
              type="submit"
              color="primary"
              disabled={!name || !roomName || !disclosures || !recordings}
            >
              Join a Room
            </Button>
          </Grid>
        </Grid>
      </form>
      <div>
        <Dialog
          open={open}
          onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <Box sx={{ width: '100%' }}>
            <DialogTitle id="alert-dialog-title">{'Servicehubcrm Virtual Portal'}</DialogTitle>
            <hr />
            <DialogContent>
              <DialogTitle id="alert-dialog-description">Please wait for the host to connect</DialogTitle>
            </DialogContent>
            <DialogActions>
              <Button variant="contained" onClick={handleCloseDialog}>
                Close
              </Button>
            </DialogActions>
          </Box>
        </Dialog>
      </div>
    </>
  );
}
