import React, { useState } from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useAppSelector } from '../../../redux/hooks';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import FirebaseApp from '../../../state/useFirebaseAuth/FirebaseApp';
import moment from 'moment';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      background: theme.brand,
      color: 'white',
      '&:hover': {
        background: '#600101',
      },
    },
  })
);

export default function EndCallButton(props: { className?: string }) {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const { room } = useVideoContext();
  const urlParams: any = useAppSelector(state => state.collection.params);
  const collection: any = useAppSelector(state => state.collection.list);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleDisconnect = async () => {
    const uid:any = process.env.REACT_APP_FIREBASE_AUTH_UID
    FirebaseApp().disconnectRoom(urlParams.data.URLRoomName, { closed: true }, uid);
  };

  const disconnectRoom = FirebaseApp().firebase.database().ref(`disconnected/${process.env.REACT_APP_FIREBASE_AUTH_UID}/${urlParams.data.URLRoomName}`);
  disconnectRoom.on('value', async (snapshot) => {
    const data = snapshot.val();
    if (data && data.closed) {
        await FirebaseApp().update(urlParams.data.URLRoomName, { closed: true , end: moment().unix()});
        const endpoint = process.env.REACT_APP_TOKEN_TWILIO + `/newcompleteroom?room_sid=${room!.sid}&company_id=${collection.tokenData.data.company_id}`;
        try {
          fetch(endpoint);
        } catch (error) {
          fetch(endpoint);
        }
        room!.disconnect();
        let url = urlParams.data.Crm === '0' ? `${collection.tokenData.decode.urlBase}` : `/disconnect?logo=${collection.tokenData.decode.urlLogo}`;
        window.location.assign(url!);
    }
  });

  return (
    <>
      <Button onClick={() => handleClickOpen()} className={clsx(classes.button, props.className)} data-cy-disconnect>
        Disconnect
      </Button>
      <div>
        <Dialog
          open={open}
          onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <Box sx={{ width: '100%' }}>
            <DialogTitle id="alert-dialog-title">{'Servicehubcrm Virtual Portal'}</DialogTitle>
            <hr />
            <DialogContent>
              <DialogTitle id="alert-dialog-description">Are you sure you want to disconnect?</DialogTitle>
            </DialogContent>
            <DialogActions>
              <Button variant="contained" onClick={handleCloseDialog}>
                No
              </Button>
              <Button variant="contained" onClick={handleDisconnect} autoFocus>
                Yes
              </Button>
            </DialogActions>
          </Box>
        </Dialog>
      </div>
    </>
  );
}
