import firebase from 'firebase';
require('firebase/firestore');
require('firebase/database');
var jwt = require('jsonwebtoken');

if (firebase.apps.length) {
  firebase.app(); // if already initialized, use that one
} else {
  firebase.initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: 'servicehub-crm-web',
    storageBucket:  process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_MESSAGING_APP_ID,
    measurementId: 'G-4ZV0C6QKFV',
  });
}

export default function FirebaseApp() {

  const tokenDataCrm = async (URLRoomName: any) => {
    let doc = await firebase
      .firestore()
      .collection('servicehubcrmvideocallFb')
      .doc(URLRoomName)
      .get();
    if (doc.exists) {
      onSnapshot(URLRoomName)
      let data = doc.data();
      let decode = jwt.decode(doc.data()?.token);
      return { data: data, decode: decode };
    } else {
      return false;
    }
  };

  const onSnapshot = async (URLRoomName: any) => {
    firebase
    .firestore()
    .collection('servicehubcrmvideocallFb')
    .doc(URLRoomName).onSnapshot(docSnapshot => {
      const data = docSnapshot.data()
      if (data?.isCanceled) {
        window.location.assign(`https://${data.host}/#/payment-expire/${data.company_id}`);
      }
    }, err => {
      console.log(`Encountered error: ${err}`);
    });
  };

  const update = async (URLRoomName: any, data: {}) => {
    await firebase
      .firestore()
      .collection('servicehubcrmvideocallFb')
      .doc(URLRoomName)
      .update(data);
  };

  const disconnectRoom = (room:string, data:any, uid:string) => {
    firebase.database().ref(`disconnected/${uid}/${room}`).set(data);
  }

  const auth = async () => {
    const mail:any = process.env.REACT_APP_FIREBASE_AUTH_MAIL
    const pass:any = process.env.REACT_APP_FIREBASE_AUTH_PASS
    const userCredential =  await firebase.auth().signInWithEmailAndPassword(mail, pass)
    return userCredential?.user?.uid
  }

  return { firebase, tokenDataCrm, update, disconnectRoom , auth};
  
}
