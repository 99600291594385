import { createSlice } from '@reduxjs/toolkit';
import { RootState } from './store';

// Define a type for the slice state
interface FirebaseState {
  list: [];
  params: [];
  isFlip: boolean; 
}

// Define the initial state using that type
const initialState: FirebaseState = {
  list: [],
  params: [],
  isFlip: false
};

export const virtualVideoSlice = createSlice({
  name: 'collection',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setColl: (state, action) => {
      state.list = action.payload;
    },
    setParams: (state, action) => {
      state.params = action.payload;
    },
    setIsFlip: (state, action) => {
      state.isFlip = action.payload;
    },
  },
});

export const { setColl, setParams, setIsFlip } = virtualVideoSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectColl = (state: RootState) => state.collection.list
export const selecParams = (state: RootState) => state.collection.params
export const selecIsFlip = (state: RootState) => state.collection.isFlip

export default virtualVideoSlice.reducer;
